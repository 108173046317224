import axios from 'axios';
import React, { useEffect, useState } from 'react';
import API from '../../components/configs/API';
import { Link } from 'react-router-dom';
import Button from '../../components/Button';
import { Calendar } from '@carbon/icons-react';

// Sample array of blog data
interface BlogPost {
  id: string;
  title: string;
  slug:string;
  content: string;
  link: string;
  image: string;
  created_on: string;
  updated_on: string;
  is_published: boolean;
  is_deleted: boolean;
  description: string;
}



const OurBlog = () => {

  const [blogPosts, setBlogPosts] = useState<BlogPost[]>([]);

  const [loading, setLoading] = useState(true); // State for loading



  const fetchData = async () => {
    try {
      const response = await API.get(`${process.env.REACT_APP_BACK_HOST}api/blog`);
      setBlogPosts(response?.data.filter((item:any)=>item.is_published));

    } catch (error) {
      console.log('xx', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const getFullLink = (link: string) => {
    return link.startsWith('http') ? link : `http://${link}`;
  };

  return (
    <div className="px-4 py-12 md:py-28 md:px-20">
      <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6  ">
        {blogPosts.map((post) => (
          <Link to={`/blog/${post.slug}`} key={post.id}>
            <div className="relative flex flex-col  bg-white shadow-sm border border-slate-200 rounded-2xl w-full h-full hover:shadow-xl">
              {/* Image */}
              <div className="relative h-40 overflow-hidden text-white rounded-t-2xl">
                <img
                  src={`${process.env.REACT_APP_BACK_HOST}${post.image.replace(/\\/g, '/')}`}
                  alt={post.title}
                  className="w-full h-full object-cover  hover:scale-105 transition-all duration-700"
                />
              </div>
              <div className="p-4 flex flex-col flex-grow">
                <h6 className="mb-2 text-slate-800 text-xl font-semibold">
                  {post.title}
                </h6>
                {/* <p className="text-slate-600 leading-normal font-light mb-5">
                  {post.description.slice(0, 200)}...
                </p> */}
                <div dangerouslySetInnerHTML={{__html:(post.description).slice(0,200)}}  className='text-slate-600 leading-normal font-light mb-5'/>
                <div className='flex justify-between mt-auto items-center'> 
                  <span className='text-gray-500 flex items-center'>< Calendar className='mr-1'/>Published on: {new Date(post?.created_on).toLocaleDateString()}</span>
                  <span className="text-blue-500 hover:underline cursor-pointer">
                    Read more
                  </span>
                </div>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>





  );

};

export default OurBlog;
